import React from 'react'
import classNames from 'classnames'
import { Location } from '@zupr/types/fo'

import Tooltip from '../components/tooltip'
import Badge from '../components/badge'

import { ReactComponent as Biker } from '../../../svg/biker.svg'
import { ReactComponent as Reservation } from '../../../svg/reservation.svg'
import { ReactComponent as PayAndCollect } from '../../../svg/pay-and-collect.svg'

import '../../../scss/react/location/features.scss'

interface LocationFeaturesProps {
    className?: string
    delivery: Location['delivery']
    allow_reservation: Location['allow_reservation']
    allow_pay_and_collect: Location['allow_pay_and_collect']
}

const LocationFeatures = ({
    className,
    delivery,
    allow_reservation,
    allow_pay_and_collect,
}: LocationFeaturesProps) => (
    <div className={classNames('location-features', className)}>
        {allow_pay_and_collect && (
            <Tooltip label="Afhalen" dutch>
                <Badge feature className="pay-and-collect">
                    <PayAndCollect />
                </Badge>
            </Tooltip>
        )}
        {allow_reservation && (
            <Tooltip label="Reserveren" dutch>
                <Badge feature className="reservation">
                    <Reservation />
                </Badge>
            </Tooltip>
        )}
        {delivery !== 'no' && (
            <Tooltip label="Lokale bezorging" dutch>
                <Badge feature className="delivery">
                    <Biker />
                </Badge>
            </Tooltip>
        )}
    </div>
)

export default LocationFeatures
